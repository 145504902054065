<template>
    <div class="operation-btn">
        <div class="table-btn"
                style="
                flex: 1;
                display: inline-flex;">
            <el-button type="primary" @click="add" v-has-permi="addPermissions">添加</el-button>
            <el-button type="danger" @click="batchDelete">批量删除</el-button>
            <div
                style="
                flex: 1;
                display: inline-flex;
            ">
                <div style="margin-left: auto;margin-right: 10px;">
                    <el-button style="margin-left:10px;" v-if="linkType" @click="buildingManagement" v-has-permi="buildingManagementPermissions">楼栋管理</el-button>
                    <el-button @click="importFile" v-has-permi="importFilePermissions">导入</el-button>
                    <el-button @click="exportFile" v-has-permi="exportFilePermissions" :loading="exportLoading">导出</el-button>
                </div>
            </div>
        </div>
        <div class="import"></div>
    </div>
</template>
<script>
export default {
    name: "OperationBtn",
    props: {
        linkType: {
            type: Boolean,
            default: () => {
                return false
            }
        },
        type: {
            type: String
        },
        exportLoading: {
            type: Boolean
        }
    },
    computed: {
        addPermissions () {
            if (this.type == 'ClassroomManagement') {
				// 教室新增
                return ['schoolbuildingroom:save']
            } else {
				// 楼栋新增
				return ['schoolbuilding:save']
			}
        },
        buildingManagementPermissions () {
            if (this.type == 'ClassroomManagement') {
                return ['schoolbuildingroom:building']
            } else {
				// 楼栋

			}
        },
        importFilePermissions () {
            if (this.type == 'ClassroomManagement') {
				// 教室导入
                return ['schoolbuildingroom:import']
            } else {
				// 楼栋导入
				return ['schoolbuilding:import']
			}
        },
        exportFilePermissions () {
            if (this.type == 'ClassroomManagement') {
				// 教室导出
                return ['schoolbuildingroom:export']
            } else {
				// 楼栋导出
				return ['schoolbuilding:expor']
			}
        }

    },
    methods: {
        selectAll () {
            this.$emit('selectAll')
        },
        inverseSelection () {
            this.$emit('inverseSelection')
        },
        clear () {
            this.$emit('clear')
        },
        add () {
            this.$emit('add')
        },
        batchDelete () {
            this.$emit('batchDelete')
        },
        buildingManagement () {
            this.$emit('buildingManagement')
        },
        importFile () {
            this.$emit('importFile')
        },
        exportFile () {
            this.$emit('exportFile')
        }
    }
}
</script>
<style lang="scss" scoped>
    .operation-btn {
        box-sizing: border-box;
        display: flex;
        flex: 1;
        justify-content: space-between;
        background-color: #fff;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;

        .el-button--text {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }
</style>
