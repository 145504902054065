<template>
    <div class="classroom-management-wrapper">
		<!-- 教室管理 教室管理 -->
        <div v-if="!errorShow">
            <loading :loadingShow="loadingShow"></loading>
            <div v-if="!loadingShow" class="content-wrapper">
                <div class="filter-wrap">
                    <expand-filter
                        :closeWidth="680"
                        marginBottom="0px"
                        :formData="formData"
                        :showExpandBtn="false"
                        @clickBtn="clickBtn"
                        @changeSel="changeSel"
                        @changeBtnFormType="changeBtnFormType"
                    ></expand-filter>
                    <div class="button-line"></div>
                    <operation-btn
                        style="white-space: nowrap; display: flex;flex: 1;"
                        type="ClassroomManagement"
                        :linkType="true"
                        :exportLoading="exportLoading"
                        @selectAll="selectAll"
                        @inverseSelection="inverseSelection"
                        @clear="clear"
                        @add="add"
                        @batchDelete="batchDelete"
                        @buildingManagement="buildingManagement"
                        @importFile="importFile"
                        @exportFile="exportFile"
                    ></operation-btn>
                </div>

                <table-data
                    ref="table"
                    id="table"
                    :to-top="false"
                    v-loading="loadingTable"
                    :config="tableConfig"
                    :table-data="tableData"
                    :background-header="backgroundHeader"
                    :header-color="headerColor"
                    @handleSelectionChange="handleSelectionChange">
                    <template v-slot:operation="operate">
                        <el-button v-has-permi="['schoolbuildingroom:edit']" type="primary" @click="handleEdit(operate.data)" class="table-success-button">编辑</el-button>
                        <el-button v-has-permi="['schoolbuildingroom:delete']" type="danger" @click="handleDel(operate.data)" class="table-danger-button">删除</el-button>
                    </template>
                </table-data>
                <Pagination :total="total" :page.sync="listQuery.pageNum" :limit.sync="listQuery.pageRow"
                    @pagination="changeGetList" />
                <dialog-wrapper :dialogObj="dialogObj" @handleClose="handleClose">
                    <classroom-forms
                        ref="classroomForms"
                        :ruleForm="ruleForm"
                        :rules="rules"
                        :classroomType="classroomType"
                        :buildingList="buildingList"
                        :floorList="floorList"
                        @handlerSortChange="handlerSortChange"
                        @selectBuilding="selectBuilding"
                        @submitForm="submitForm"
                        class="common-form"
                    >
                    </classroom-forms>
                </dialog-wrapper>
                <dialog-wrapper :dialogObj="dialogObjImport" @handleClose="handleCloseImport">
                    <import-class-info
                        ref="importExcelFile"
                        :importExcel="importExcel"
                        :active="active"
                        :successNum="successNum"
                        @download="download"
                        @uploadChange="uploadChange"
                        @delFile="delFile"
                        @importSaveFile="importSaveFile"
                        @resetFile="resetFile"
                        @finish="finish"
                        class="common-form"
                    >
                    </import-class-info>
                </dialog-wrapper>
            </div>
        </div>
        <error v-else :errorShow="errorShow"></error>
    </div>
</template>
<script>
import {
    Error,
    Loading,
    Pagination,
    DialogWrapper,
    debounce
} from "common-local"
import ExpandFilter from '../../Sub/ExpandFilter.vue';
import OperationBtn from '../Sub/OperationBtn.vue';
import TableData from "@/components/scrollWrapper/Sub/TableData"
import ClassroomForms from './ClassroomForms.vue'
import ImportClassInfo from './ImportClassInfo.vue';
import { downloadFile } from "@/libs/utils.js";
import { SManagerBaseClassroomModule } from '@/models/SManagerBaseClassroom.js';
import onResize from "@/mixins/onResize"
import RecordsList from '@/libs/pageListOptimal.js';

import { mapState } from 'vuex'
export default {
    mixins: [onResize],
    name: "ClassroomManagement",
    components: {
        Error,
        Loading,
        TableData,
        Pagination,
        DialogWrapper,
        ExpandFilter,
        OperationBtn,
        ClassroomForms,
        ImportClassInfo
    },
    data () {
        return {
            // 功能组件
            errorShow: false,
            loadingShow: false,
            loadingTable: true,
            // 分页器
            total: 0,
            formData: {
                formInline: {
                    roomType: '',
                    buildingId: '',
                    floorId: '',
                    roomName: '',
                },
                data: [
                    {
                        type: 'select',
                        label: '',
                        placeholder: '教室类型',
                        key: 'roomType',
                        value: '',
                        list: []
                    },
                    {
                        type: 'select',
                        label: '',
                        placeholder: '所在楼栋',
                        key: 'buildingId',
                        value: '',
                        list: []
                    },
                    {
                        type: 'select',
                        label: '',
                        placeholder: '所在楼层',
                        key: 'floorId',
                        value: '',
                        list: []
                    },
                    {
                        type: 'input',
                        label: '',
                        placeholder: '教室名称',
                        key: 'roomName',
                        value: ''
                    }
                ],
                btnList: [
                    {
                        type: 'primary',
                        text: '查询',
                        fn: 'primary',
                        auth:'product:list',
                    },
                    {
                        type: "enquiry",
                        text: '重置',
                        fn: 'reset'
                    },

                ],
                btnFormType: true
            },
            listQuery: {
                pageNum: 1,
                pageRow: 20,
                schoolId: '',
                roomType: '',
                buildingId: '',
                floorId: '',
                roomName: ''
            },
            // 表头
            backgroundHeader: '#FAFBFC',
            // 表头字体
            headerColor: '#595959',
            tableConfig: {
                height: "",
                check: true,
                thead: [
                    {
                        label: '教室名称',
                        prop: 'roomName',
                        align: 'center'
                    },
                    {
                        label: '教室类型',
                        prop: 'roomTypeName',
                        align: 'center',
                        labelWidth:'180px',
                    },
                    {
                        label: '教室描述',
                        prop: 'roomDes',
                        defaultValue: '-',
                        align: 'center',
                    },
                    {
                        label: '所在楼栋',
                        prop: 'buildingName',
                        align: 'center',
                        labelWidth:'160px',
                    },
                    {
                        label: '楼层',
                        prop: 'floorName',
                        align: 'center',
                        labelWidth:'160px',
                        className: 'text-spacing-reduction'
                    },
                    {
                        label: '门牌号',
                        prop: 'roomNum',
                        labelWidth:'160px',
                        align: 'center'
                    },
                    {
                        label: '排序值',
                        prop: 'sort',
                        labelWidth:'180px',
                        sortable: true,
                        align: 'center'
                    },
                    {
                        label: '创建时间',
                        prop: 'createTime',
                        type: 'function',
                        align: 'center',
                        labelWidth: '180',
                        callBack: (row) => {
                            return row.createTime ? row.createTime.slice(0, -3) : '-'
                        }
                    },
                    {
                        label: '操作',
                        type: 'slot',
                        slotName: 'operation',
                        className: 'text-spacing-reduction'
                    }
                ]
            },
            tableData: [],
            // 弹出框配置
            dialogObj: {
                title: '添加教室',
                dialogVisible: false,
                width: '640px'
            },
            dialogObjImport: {
                title: '导入教室信息',
                dialogVisible: false,
                width: '640px'
            },
            ruleForm: {
                id: '',
                schoolId: '',
                roomType: '', //教室类型
                roomName: '', //教室名称
                roomDes: '', //教室描述
                buildingId: '',// 所在楼栋
                floorId: '', // 所在楼层
                roomNum: '', // 门牌号
                sort: '', //排序
            },
            rules: {
                roomType: [
                    { required: true, message: '请选择教室类型', trigger: ['change', 'blur'] },
                ],
                roomName: [
                    { required: true, message: '请输入教室名称', trigger: 'blur' },
                ],
                buildingId: [
                    { required: true, message: '请选择所在楼栋', trigger: 'change' },
                ],
                floorId: [
                    { required: true, message: '请选择所在楼层', trigger: 'change' },
                ]

            },
            classroomType: [], // 教室类型
            buildingList: [], // 所在楼栋
            floorList: [], // 所在楼层
            deleteCollection: [], // 删除操作
            importExcel: {
                file: '',
                errType: '',
                schoolId: ''
            }, // 导入教室
            active: '0', // 进度
            successNum: 0, // 成功条数
            exportLoading: false
        }
    },
    computed: {
        ...mapState({
            schoolId: state => state.schoolId
        })
    },
    created () {
        this.init();
    },
    mounted() {
        console.log(this.formData,'formData');
    },
    methods: {
        /**
         * @Description:
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author:
         * @Date: 2023-01-12 09:32:34
         */
        init () {
            this.initAssignment();
            this.initPageData();
        },
        initAssignment () {
            this.ruleForm.schoolId = this.schoolId;
            this.importExcel.schoolId = this.schoolId;
            this.listQuery.schoolId = this.schoolId;
            let recordsList = new RecordsList();
            if(recordsList.get(this.$route.name)){
                this.formData = recordsList.get(this.$route.name).formData;
                this.listQuery = recordsList.get(this.$route.name).listQuery;
            }
        },
        async initPageData () {
            await this.getClassroomType()
            await this.getBuilding()
            await this.getList()
        },
        /**
         * @Description: 教室类型
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-01-13 08:58:35
         */
        async getClassroomType () {
            const sManagerBaseClassroomModule = new SManagerBaseClassroomModule();
            await sManagerBaseClassroomModule.getConditionList({schoolId: this.schoolId, dictKey: 'classRoomType'}).then((res) => {
                // console.log("[getClassroomType]res:", res)
                if (res.data.code == '200') {
                    res.data.data.forEach((item) => {
                        this.$set(item, 'label', item.name)
                        this.$set(item, 'value', item.id)
                    })
                    this.formData.data.forEach((item) => {
                        console.log(item, 'item')
                        if (item.key == 'roomType') {
                            item.list = res.data.data;
                        }
                    })
                    this.classroomType = res.data.data;
                    // console.log(res.data.data,'教室类型')
                } else if (res.data.msg) {
                    this.$message.error(res.data.msg)
                }
            })
        },
        /**
         * @Description: 所在楼栋
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-01-13 08:58:53
         */
        async getBuilding () {
            const sManagerBaseClassroomModule = new SManagerBaseClassroomModule();
            await sManagerBaseClassroomModule.getBuildingList({schoolId: this.schoolId}).then((res) => {
                if (res.data.code == '200') {
                    res.data.data.forEach((item) => {
                        this.$set(item, 'label', item.buildingName)
                        this.$set(item, 'value', item.id)
                    })
                    this.formData.data.forEach((item) => {
                        console.log(item, 'item')
                        if (item.key == 'buildingId') {
                            item.list = res.data.data;
                        }
                    })
                    this.buildingList = res.data.data;
                    console.log(res.data.data,'楼栋列表')
                } else if (res.data.msg) {
                    this.$message.error(res.data.msg)
                }
           })

        },
        /**
         * @Description: 获取所在楼栋
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-01-13 09:18:37
         */
         changeSel (data) {
            console.log(data,'0000000000000000000000000');
            if (data.key == 'buildingId') {
                if(data.value){
                    const sManagerBaseClassroomModule = new SManagerBaseClassroomModule();
                    sManagerBaseClassroomModule.getBuildingFloorList({schoolId: this.schoolId, buildingId: data.value}).then((res) => {
                        if (res.data.code == '200') {
                            res.data.data.forEach((item) => {
                                this.$set(item, 'label', item.floorName)
                                this.$set(item, 'value', item.id)
                            })
                            this.formData.data.forEach((item) => {
                                if (item.key == 'floorId') {
                                    item.list = res.data.data;
                                    item.value = '';
                                }
                            })
                        }
                    })
                }else{
                    this.formData.data.forEach((item) => {
                        if (item.key == 'floorId') {
                            item.list = [];
                            item.value = '';
                        }
                    })
                }
            }
         },
        /**
         * @Description: 稍选提交
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author:
         * @Date: 2023-01-12 09:22:06
         * @param {*} data
         */
        clickBtn (data) {
            switch (data.item.fn) {
                    case "primary": //查询
                        this.formData.data.forEach((item) => {
                            Object.keys(this.listQuery).forEach((key) => {
                                if (item.key == key) {
                                    this.listQuery[key] = item.value;
                                }
                            })
                        })
                        this.getList()
                        break;
                    case "reset": //重置
                        this.formData.data.forEach((item) => {
                            item.value = '';
                            if (item.key == 'floorId') {
                                item.list = [];
                            }
                        })
                        Object.keys(this.listQuery).forEach((key) => {
                            this.listQuery[key] = ''
                        })
                        this.listQuery.pageNum = 1;
                        this.listQuery.pageRow = 20;
                        this.listQuery.schoolId = this.schoolId;
                        this.getList()
                        break;
                    default:
                        break;
                }
        },
        /**
         * @Description: 关闭弹窗
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author:
         * @Date: 2023-01-12 09:21:51
         */
        handleClose () {
            this.$refs.classroomForms.resetForm()
            this.dialogObj.dialogVisible = false;
        },
        changeGetList(){
            this.$nextTick(() => {
                this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop = 0
            });
            this.getList()
        },
        /**
         * @Description:
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author:
         * @Date: 2023-01-12 09:24:31
         */
        getList () {
            this.loadingTable = true;
            const sManagerBaseClassroomModule = new SManagerBaseClassroomModule();
            sManagerBaseClassroomModule.getClassroomList(this.listQuery).then((res) => {
                if (res.data.code == '200') {
                    this.tableData = res.data.data.list;
                    this.total = res.data.data.totalCount;
                    //获取--存储筛选项及滚动条高度
                    this.$nextTick(() => {
                        let recordsList =  new RecordsList()
                        if(recordsList.get(this.$route.name)){
                            this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop = recordsList.get(this.$route.name).tableScrollTop
                        }
                        console.log('获取--存储筛选项及滚动条高度');
                        //移除--存储筛选项及滚动条高度
                        recordsList.remove(this.$route.name)
                    });
                } else if (res.data.msg) {
                    this.$message.error(res.data.msg)
                }
                this.loadingTable = false;
            })
            .catch(() => {
                this.loadingTable = false;
            });
        },
        /**
         * @Description: 操作项
         * selectAll 全选
         * inverseSelection 反旋
         * clear 清除
         * add 添加
         * batchDelete 批量删除
         * buildingManagement 楼栋管理
         * importFile 导入
         * exportFile 导出
         * getSelectTableData 获取表格选中数据
         */
        selectAll () {
            this.$refs.table.$refs.multipleTable.toggleAllSelection()
            this.getSelectTableData()
        },
        inverseSelection () {
            this.tableData.forEach((item) => {
                this.$refs.table.$refs.multipleTable.toggleRowSelection(item);
            });
            this.getSelectTableData()
        },
        clear () {
            this.$refs.table.$refs.multipleTable.clearSelection()
            this.getSelectTableData()
        },
        add () {
            this.ruleForm.id = '';
            this.ruleForm.sort = 1;
            this.dialogObj.title = '添加教室';
            this.dialogObj.dialogVisible = true;
            if (this.$refs.classroomForms) {
                this.$refs.classroomForms.resetForm()
            }
        },
        batchDelete () {
            if (this.deleteCollection.length == 0) {
                return this.$message.error('未选择任何数据～')
            }
            this.$confirm('确定要删除吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                const sManagerBaseClassroomModule = new SManagerBaseClassroomModule();
                sManagerBaseClassroomModule.schoolBuildingRoomDel(this.deleteCollection).then((res) => {
                    if (res.data.code == '200') {
                        this.$message.success('删除成功')
                        this.getList()
                    } else if (res.data.msg) {
                        this.$message.error(res.data.msg)
                    }
                })
                }).catch(() => {});
        },
        buildingManagement () {
            //存储筛选项及滚动条高度
            let recordsList =  new RecordsList()
            recordsList.set(this.$route.name, {
                formData: this.formData,
                listQuery:  this.listQuery,
                tableScrollTop: this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop
            })
            this.$emit('buildingManagement')
        },
        importFile () {
            this.dialogObjImport.dialogVisible = true;
        },
        exportFile () {
            let obj = {
                schoolId: this.schoolId,
                roomType: '',
                buildingId: '',
                floorId: '',
                roomName: ''
            };
            this.formData.data.forEach((item) => {
                Object.keys(obj).forEach((key) => {
                    if (item.key == key) {
                        obj[key] = item.value;
                    }
                })
            })
            this.exportLoading = true;
            downloadFile(
                { url: "/school/schoolBuildingRoom/export", form: obj },
                () => {
                    this.$message.success("导出成功");
                    this.exportLoading = false;
                },
                () => {}
            );
        },
        getSelectTableData () {
            this.deleteCollection = [];
            const selectData = this.$refs.table.$refs.multipleTable.selection;

            selectData.forEach((item) => {
                let obj = {
                    id: item.id
                }
                this.deleteCollection.push(obj);
            })
        },
        handleSelectionChange (e) {
            this.deleteCollection = [];

            e.forEach((item) => {
                let obj = {
                    id: item.id
                }
                this.deleteCollection.push(obj);
            })
        },
        /**
         * @Description: 关闭导入弹窗
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-01-12 10:21:48
         */
         handleCloseImport () {
            this.$refs.importExcelFile.delFile();
            this.finish()
         },
        /**
         * @Description:
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author:
         * @Date: 2023-01-12 15:57:04
         */
         async handleEdit (data) {
            console.log(data,'data')
            this.ruleForm.id = data.id;
            Object.keys(this.ruleForm).forEach((key) => {
                Object.keys(data).forEach((subKey) => {
                    if (key == subKey) {
                        this.ruleForm[key] = data[subKey];
                    }
                })
            })
            this.ruleForm.schoolId = this.schoolId;
            if (!data.sort) {
                this.ruleForm.sort = undefined;
            }

            await this.selectBuilding('edit')

            this.dialogObj.title = '编辑教室信息'
            this.dialogObj.dialogVisible = true;
         },
        /**
         * @Description:
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author:
         * @Date: 2023-01-12 15:57:20
         */
         handleDel (data) {
            this.$confirm('确定要删除吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                const sManagerBaseClassroomModule = new SManagerBaseClassroomModule();
                sManagerBaseClassroomModule.schoolBuildingRoomDel([{id: data.id}]).then((res) => {
                    if (res.data.code == '200') {
                        this.$message.success('删除成功')
                        this.getList()
                    } else if (res.data.msg) {
                        this.$message.error(res.data.msg)
                    }
                })
            }).catch(() => {});
        },
        /**
         * @Description:
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author:
         * @Date: 2023-01-13 10:44:20
         */
        async selectBuilding (type) {
            const sManagerBaseClassroomModule = new SManagerBaseClassroomModule();
            await sManagerBaseClassroomModule.getBuildingFloorList({schoolId: this.schoolId, buildingId: this.ruleForm.buildingId}).then((res) => {
                    if (res.data.code == '200') {
                        if (type !== 'edit') {
                            this.ruleForm.floorId = '';
                        }

                        res.data.data.forEach((item) => {
                            this.$set(item, 'label', item.floorName)
                            this.$set(item, 'value', item.id)
                        })
                        this.floorList = res.data.data;
                    }
                })
        },
        /**
         * @Description:
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author:
         * @Date: 2023-01-18 17:33:28
         */
         handlerSortChange () {
            console.log(this.ruleForm.sort, 'this.ruleForm.sort')
            //
            if (!this.ruleForm.sort) {
                this.ruleForm.sort = undefined;
            }
         },
        /**
         * @Description: 新增/编辑
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author:
         * @Date: 2023-01-13 10:59:54
         */
         submitForm () {
            if (!this.ruleForm.sort) {
                this.ruleForm.sort = '';
            }
            const sManagerBaseClassroomModule = new SManagerBaseClassroomModule();
            sManagerBaseClassroomModule.classroomSave(this.ruleForm).then((res) => {
                if (res.data.code == '200') {
                    this.$message.success(res.data.msg)
                    this.$refs.classroomForms.resetForm()
                    this.dialogObj.dialogVisible = false;
                    this.ruleForm.id = '';
                    this.getList();
                } else if (res.data.msg) {
                    this.$message.error(res.data.msg)
                }
            })
        },
    /**
     * @Description:
     * @DoWhat:
     * @UseScenarios:
     * @Attention:
     * @Author:
     * @Date: 2023-01-13 11:46:03
     */
     download () {
        this._get(
        "/school/schoolBuildingRoom/template"
      ).then((res) => {
        if (res.data.code === "200") {
            downloadFile(
                {
                url: res.data.data,
                method: "get",
                },
                () => {},
                () => {}
            );
        }
      });
    },
    /**
     * @Description: 上传文件
     * @DoWhat:
     * @UseScenarios:
     * @Attention:
     * @Author: 武东超
     * @Date: 2023-01-13 14:32:23
     */
    uploadChange (file) {
        this.importExcel.file = file.raw;
        console.log(file,'file')
    },
    /**
     * @Description: 清除上传的文件
     * @DoWhat:
     * @UseScenarios:
     * @Attention:
     * @Author:
     * @Date: 2023-01-13 14:34:47
     */
     delFile () {
        this.importExcel.file = ''
     },
    /**
     * @Description: 导入
     * @DoWhat:
     * @UseScenarios:
     * @Attention:
     * @Author:
     * @Date: 2023-01-13 14:25:57
     */
     importSaveFile () {
        if(!this.importExcel.file) {
            return this.$message.error('请上传文件')
        }
        if(!this.importExcel.errType) {
            return this.$message.error('请选择导入设置')
        }
        console.log(this.importExcel, '数据')
        let formData = new FormData();
        formData.append("file", this.importExcel.file);
        formData.append("errType", this.importExcel.errType);
        formData.append("schoolId", this.schoolId);

        const sManagerBaseClassroomModule = new SManagerBaseClassroomModule();
        sManagerBaseClassroomModule.importExcel(formData).then((res) => {
            if (res.data.code == '200') {
                console.log(res.data,'导入成功')
                this.$message.success('导入成功')
                this.active = this.active + '1';
                console.log('res.data.data', res.data.data)
                this.successNum = res.data.data;
            } else if (res.data.code == "20013") {
                console.log(res.data,'导入错误')
                this.downloadErrorList(res.data);
            } else {
                this.$message.error(res.data.msg)
            }
        })
     },
     downloadErrorList (data) {
        this.$confirm(data.msg, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
        })
        .then(() => {
            downloadFile(
                {
                    url: "/school/schoolBuildingRoom/errorExport",
                    method: "post",
                    form: { paramData: data.info.paramData },
                },
                () => {},
                () => {

                }
            );
            })
            .catch(() => {});
        },
        /**
         * @Description: 重置
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author:
         * @Date: 2023-01-13 14:28:48
         */
        resetFile () {
            console.log(this.importExcel,'this.importExcel')
            this.importExcel.file = '';
            this.importExcel.errType = '';
        },
        /**
         * @Description: 导入完成
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-01-13 15:29:23
         */
        finish (type) {
            this.dialogObjImport.dialogVisible = false;
            this.importExcel.file = '';
            this.importExcel.errType = '';
            this.active = '0';
            this.successNum = 0;
            if (type == 'getNew') {
                this.getList();
            }

        },
    }
}
</script>
<style lang="scss" scoped>
.content-wrapper{
    padding-right: 10px;
}
.filter-wrap {
    display: flex;
    align-items: flex-start;
    background-color: #fff;
    padding: 10px 20px 0;
    overflow: auto;
    margin-bottom: 10px;
    border-radius: 4px;

    .expand-filter {
        padding: 0;
    }
}
</style>
